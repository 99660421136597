import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"

import { PageLabel } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

function QuickLink(props) {
  return (
    <Flex
      alignItems="center"
      flexWrap="no-wrap"
      justifyContent="space-between"
      height="full"
      width="full"
    >
      <Box as="a" href={props.to} height="full" width="full">
        <PageLabel
          height="full"
          bg="white"
          py={3}
          borderLeftWidth="4px"
          borderLeftColor="secondary"
        >
          <Box as="span" fontFamily="heading" fontSize={["xl", "xl", "2xl"]}>
            {props.title}
          </Box>
          <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
        </PageLabel>
      </Box>
    </Flex>
  )
}

export default QuickLink
