import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { Link } from "gatsby"

import {
  Container,
  Nav,
  NavSecondary,
  MobileNav,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout/"
import {
  Logo,
  NavLink,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"

const Header = (props) => {
  const isHealthcare = (href) => {
    return href !== "/academics" ? { className: "active" } : { className: " " }
  }

  return (
    <Flex as="header" flexWrap="wrap">
      <Box bg="primary" width="full">
        <Container>
          <Flex
            flexWrap="no-wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box width={["full", "full", "full", 1 / 4]}>
              <Box
                as="ul"
                m={0}
                sx={{
                  listStyle: "none",
                  justifyContent: ["flex-start", "flex-start", "flex-end"],
                  li: {
                    display: ["inline-flex"],
                  },
                  "li a": {
                    color: "gray.200",
                    paddingTop: "1em",
                    paddingBottom: "1em",
                    textDecoration: "none",
                    fontSize: "sm",
                    borderTop: "4px solid transparent",
                  },
                  "li a:hover,  li a.active, .hc-active": {
                    borderTop: "4px solid white",
                    color: "white",
                  },
                }}
              >
                <NavLink pl={0}>
                  <Link to="/academics" activeClassName="academics">
                    Academics
                  </Link>
                </NavLink>
                <NavLink>
                  <Link to="/" activeClassName="active" getProps={isHealthcare}>
                    Healthcare
                  </Link>
                </NavLink>
              </Box>
            </Box>
            <NavSecondary
              display={["none", "none", "none", "flex"]}
              justifyContent="flex-end"
              alignItems="center"
              width={[0, 0, 3 / 4, 3 / 4]}
              sx={{
                a: {
                  alignItems: "center",
                  borderTop: "2px solid transparent",
                  color: "gray.200",
                  display: "flex",
                  textDecoration: "none",
                  fontSize: "sm",
                },
                "a:hover": {
                  color: "white",
                },
                svg: {
                  fill: "current",
                  fontSize: "md",
                },
                span: {
                  padding: "0 0 0 4px",
                },
              }}
            />

            <MobileNav />
          </Flex>
        </Container>
      </Box>

      <Box py={8} backgroundColor="white" overflow="hidden" width="full">
        <Container>
          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              width={["full", "full", "full", 260]}
              sx={{
                a: {
                  padding: "0",
                  margin: "0",
                },
                ".logo": {
                  maxWidth: "260px",
                  display: "block",
                  py: "2",
                },
              }}
            >
              <Link to="/" aria-label="WVU Cancer Institute Logo">
                <Logo />
              </Link>
            </Box>

            <Box>
              <Nav
                display={["none", "none", "none", "block"]}
                sx={{
                  a: {
                    color: "gray.700",
                    textDecoration: "none",
                  },
                  "a:hover, a.active": {
                    color: "black",
                  },
                }}
              />
            </Box>
          </Flex>
        </Container>
      </Box>
    </Flex>
  )
}

export default Header
