import React from "react"
import { Global as GlobalCss, css } from "@emotion/react"
import theme from "@giraldomac/gatsby-theme-wvumedicine/src/@chakra-ui/gatsby-plugin/theme"

const Global = () => {
  const breakpoints = [640, 768, 1024, 1280]

  const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`)

  return (
    <GlobalCss
      styles={css`
        [data-reach-skip-link] {
          background: white;
          left: -99999em;
          padding: 0.5em;
          position: absolute;
          z-index: 10;
        }
        [data-reach-skip-link]:focus {
          left: 0.5em;
          top: 0.5em;
        }
        .breadcrumb .breadcrumb__list {
          list-style: none;
          margin: 0;
          padding: 0;
          position: absolute;
          bottom: 1em;
          ${mq[0]} {
            bottom: 1.5em;
          }
        }
        .breadcrumb__list__item {
          display: inline-block;
        }
        .breadcrumb__link {
          color: white;
          font-size: 12px;
          text-decoration: none;
          text-transform: uppercase;
          &:hover {
            text-decoration: underline;
          }
        }

        .academics {
          border-color: "blueviolet";
        }

        .datocms-widget {
          margin: 0 auto;
          padding: 5em 2em;
          height: 500vh;
          width: 100%;
          ${mq[2]} {
            width: 80%;
          }
        }
        .datocms-widget__result {
          margin-bottom: 15px;
          border-left: 3px solid transparent;
          padding-left: 15px;
          border-left-color: ${theme.colors.gray[300]};
          ${mq[1]} {
            padding-right: 18px;
          }
        }
        .datocms-widget__result:hover {
          border-left-color: ${theme.colors.primary};
        }
        .datocms-widget__result__title {
          color: ${theme.colors.primary};
          display: block;
          font-size: ${theme.fontSizes.lg};
          text-decoration: underscore;
          margin-bottom: 3px;
        }
        .datocms-widget__result__body {
          font-size: ${theme.fontSizes.md};
          margin-bottom: 10px;
          margin-bottom: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .datocms-widget__result__url {
          margin-bottom: 10px;
          font-size: ${theme.fontSizes.sm};
          color: ${theme.colors.gray[500]};
        }
        .datocms-widget__loading__inner-1,
        .datocms-widget__loading__inner-2 {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.2);
          opacity: 0.6;
          position: absolute;
          top: 0;
          left: 0;
          -webkit-animation: datocms-widget-bounce 2s infinite ease-in-out;
          animation: datocms-widget-bounce 2s infinite ease-in-out;
        }
        .datocms-widget__loading__inner-2 {
          -webkit-animation-delay: -1s;
          animation-delay: -1s;
        }
        .datocms-widget__body {
          position: relative;
        }
        @media screen and (min-width: 600px) {
          .datocms-widget__body {
            min-height: 80px;
            max-height: 60vh;
            overflow: auto;
          }
        }
        .datocms-widget__loading {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -20px;
          margin-left: -20px;
          width: 40px;
          height: 40px;
        }
        .datocms-widget__no-results__label {
          text-align: center;
          padding: 40px;
          font-size: ${theme.fontSizes["2xl"]};
          color: ${theme.colors.gray[600]};
        }
        .datocms-widget__header {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid lightgray;
        }
        .datocms-widget__search__input {
          width: 100%;
          box-sizing: border-box;
          border: 0;
          padding: 0;
          font-size: ${theme.fontSizes["2xl"]};
          padding: 18px;
        }
        .datocms-widget__search__input:focus {
          outline: none;
        }
        @media screen and (max-width: 599px) {
          .datocms-widget__search__input {
            margin-bottom: 20px;
          }
          .datocms-widget__search__input {
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
          }
        }
        @media screen and (min-width: 600px) {
          .datocms-widget__header__inner {
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
          }
          .datocms-widget__search {
            flex: 1;
          }
        }
        .datocms-widget__footer {
          margin-top: 15px;
          padding-top: 15px;
          border-top: 1px solid lightgray;
          font-size: ${theme.fontSizes.md};
          color: #666;
          display: flex;
          align-items: center;
        }
        .datocms-widget__total {
          text-align: right;
          padding: 5px;
        }
        .datocms-widget__pagination {
          flex: 1;
        }
        .datocms-widget__pagination__page {
          display: inline-block;
          padding: 5px;
          margin-bottom: 3px;
        }
        .datocms-widget__pagination__page:hover {
          text-decoration: underscore;
        }
        .datocms-widget__pagination__page.is-active {
          text-decoration: none;
          color: ${theme.colors.gray[600]};
        }
        @keyframes datocms-widget-bounce {
          0%,
          100% {
            transform: scale(0);
            -webkit-transform: scale(0);
          }
          50% {
            transform: scale(1);
            -webkit-transform: scale(1);
          }
        }

        .btn {
          display: inline-flex;
          cursor: pointer;
          appearance: none;
          align-items: center;
          justify-content: center;
          user-select: none;
          position: relative;
          white-space: nowrap;
          vertical-align: middle;
          outline: transparent solid 2px;
          outline-offset: 2px;
          width: auto;
          line-height: 1.2;
          border-radius: var(--chakra-radii-md);
          font-weight: 800;
          font-family: var(--chakra-fonts-body);
          text-transform: uppercase;
          text-decoration: none;
          height: var(--chakra-sizes-10);
          min-width: var(--chakra-sizes-10);
          font-size: var(--chakra-fontSizes-sm);
          padding-inline-start: var(--chakra-space-4);
          padding-inline-end: var(--chakra-space-4);
          background: var(--chakra-colors-primary);
          color: var(--chakra-colors-white);
          margin-top: var(--chakra-space-3);
        }
      `}
    />
  )
}

export default Global
