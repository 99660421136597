import React from "react"
import { Box } from "@chakra-ui/react"

const Hero = props => (
  <Box
    backgroundColor="gray.8"
    color="white"
    overflow="hidden"
    position="relative"
    zIndex={0}
    {...props}
  >
    {props.children}
  </Box>
)

export default Hero
