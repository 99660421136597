import React from "react"
import { Box } from "@chakra-ui/react"

const SidebarLinks = ({ page }) => {
  return (
    <Box
      sx={{
        ul: { mx: 0, my: 3, padding: 0, listStyle: "none" },
        li: {
          borderBottomColor: "blackAlpha.200",
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          m: 0,
          py: 1,
        },
        a: { textDecoration: "none" },

        h4: {
          mt: 6,
        },
      }}
      dangerouslySetInnerHTML={{
        __html: page.linksNode.childMarkdownRemark.html,
      }}
    />
  )
}

export default SidebarLinks
