import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { chakra, Box, Button, SimpleGrid } from "@chakra-ui/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Overlay } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const ChakraImg = chakra(GatsbyImage)
const colors = ["accent.4", "accent.7", "accent.1", "accent.6"]

const GivingOpportunities = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCancerGivingOpportunity(
        sort: { fields: position, order: ASC }
      ) {
        edges {
          node {
            id
            introContent
            slug
            title
            position
            heroImage {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return (
    <Box display="block">
      <SimpleGrid columns={[1, 1, 2]}>
        {data.allDatoCmsCancerGivingOpportunity.edges.map(
          ({ node: way }, index) => (
            <Box
              key={way.id}
              overflow="hidden"
              position="relative"
              width={["full", "full", "1/2"]}
            >
              <Box
                overflow="hidden"
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                width="full"
                height="full"
                zIndex={0}
              >
                <ChakraImg
                  image={way.heroImage.gatsbyImageData}
                  alt={way.title}
                  objectFit="contain"
                  width="full"
                  height="full"
                />
              </Box>
              <Overlay bg={`${colors[index]}`} opacity={0.9} />
              <Box
                position="relative"
                px={[9, 9, 12, 16]}
                py={[16, 16, 20, 24]}
                color="white"
                zIndex={1}
                sx={{ "*": { color: "white" } }}
              >
                <Box as="h3">{way.title}</Box>
                <Box as="p" maxWidth="600px">
                  {way.introContent}
                </Box>
                <Link to={way.slug} style={{ textDecoration: "none" }}>
                  <Button as="span" variant="outline">
                    Learn More
                  </Button>
                </Link>
              </Box>
            </Box>
          )
        )}
      </SimpleGrid>
    </Box>
  )
}

export default GivingOpportunities
