import React from "react"

export default function FlyingWVBg(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 1280 400"
      fill="currentColor"
      {...props}
    >
      <g>
        <path d="M1201.3 0L933.7 400h83.4L1280 7V0h-78.7zM437.9 350.6l-33 49.4h65.9l-32.9-49.4zm66-99L462.3 314l57.4 86h83.2l-99-148.4zM884.2 400L1151 0h-83.2L801 400h83.2z" />
        <path d="M479.5 215l24.4-36.6L651.7 400h100.5L1019 0h-83.2L701.9 350.6l-198-296.9-198 296.9L72 0H0v16.9L255.6 400h100.5l68.7-103.1 54.7-81.9z" />
        <path d="M490.9 0h-83.2L305.8 152.7 204.2 0h-83l184.6 277.4L490.9 0zm211 277.4L887 0h-83.2L701.9 152.7 600.1 0h-83.2l185 277.4z" />
      </g>
    </svg>
  )
}
