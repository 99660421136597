import React from "react"
import { Box, Button, Link as LinkExt, UnorderedList } from "@chakra-ui/react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLaptopMedical,
  faUserMd,
  faCalendarPlus,
  faHeart,
} from "@fortawesome/pro-regular-svg-icons"

import { NavLink } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"

const NavSecondary = props => {
  return (
    <UnorderedList width="auto" m={0} {...props}>
      <NavLink>
        <LinkExt href="https://mywvuchart.com/MyChart/">
          <FontAwesomeIcon icon={faLaptopMedical} />
          <span>MyWVUChart</span>
        </LinkExt>
      </NavLink>
      <NavLink>
        <LinkExt href="https://wvumedicine.org/find-a-doctor/">
          <FontAwesomeIcon icon={faUserMd} />
          <span>Find a Doctor</span>
        </LinkExt>
      </NavLink>
      <NavLink>
        <LinkExt href="https://wvumedicine.org/online-appointment-primary-care-physician/">
          <FontAwesomeIcon icon={faCalendarPlus} />
          <span>Make an Appointment</span>
        </LinkExt>
      </NavLink>
      <NavLink>
        <Link to="/giving">
          <Button
            variant="solid"
            bg="secondary"
            color="primary"
            fontFamily="body"
            fontWeight="normal"
            fontSize="sm"
            textTransform="initial"
            _hover={{ bg: "secondaryHover" }}
          >
            <Box as="span" mr={1} ml={0} color="red.600">
              <FontAwesomeIcon icon={faHeart} />
            </Box>
            Giving
          </Button>
        </Link>
      </NavLink>
    </UnorderedList>
  )
}

export default NavSecondary
