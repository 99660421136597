import React from "react"
import {
  Box,
  Button,
  Divider,
  Link as LinkExt,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { Card } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const CentersGrid = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCenter(sort: { fields: position }) {
        edges {
          node {
            slug
            websiteUrl
            centerName
            id
            position
            meta {
              status
            }
            address {
              addressLine2
              city
              zipCode
              streetAddress
              state
              id
            }
            parentEntity {
              ... on DatoCmsInstitute {
                id
                instituteName
              }
            }
            phoneNumbers {
              phoneNumber
              phoneNumberTitle
              id
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <SimpleGrid
        columns={[1, 1, 2, 3]}
        spacing={6}
        wrap={["nowrap", "nowrap", "wrap"]}
      >
        {data.allDatoCmsCenter.edges.map(({ node: center }) => (
          <Card
            key={center.id}
            borderTopColor="secondary"
            borderTopWidth={6}
            p={[3, 3, 6]}
          >
            <VStack
              flexDirection="column"
              justifyContent="space-between"
              height="full"
            >
              <Box width="full">
                <h3>{center.centerName}</h3>

                {center.address.map(({ ...address }) => (
                  <Box key={address.id}>
                    <p>
                      {address.streetAddress}
                      {address.addressLine2 && <br />}
                      {address.addressLine2 && address.addressLine2}
                      <br />
                      {address.city}, {address.state} {address.zipCode}
                    </p>
                  </Box>
                ))}

                {center.phoneNumbers.map(({ ...phoneNumbers }) => (
                  <Box key={phoneNumbers.id}>
                    <p>
                      <a href={`tel:${phoneNumbers.phoneNumber}`}>
                        {phoneNumbers.phoneNumber}
                      </a>
                    </p>
                  </Box>
                ))}
              </Box>
              <Box width="full">
                <Divider />
                <Button
                  as="span"
                  mt={6}
                  textAlign="right"
                  variant="outline"
                  size="sm"
                  sx={{
                    "a, a:link": { color: "blue.400", textDecoration: "none" },
                    "a:hover": { textDecoration: "none" },
                  }}
                >
                  {center.websiteUrl ? (
                    <LinkExt
                      href={center.websiteUrl}
                      color="blue.500"
                      _hover={{ textDecoration: "none" }}
                    >
                      Learn More{" "}
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{ marginLeft: "3" }}
                      />
                    </LinkExt>
                  ) : (
                    <Link to={`/about-us/centers/${center.slug}`}>
                      Learn More{" "}
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{ marginLeft: "3" }}
                      />
                    </Link>
                  )}
                </Button>
              </Box>
            </VStack>
          </Card>
        ))}
      </SimpleGrid>
    </>
  )
}

export default CentersGrid
