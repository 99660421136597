import React from "react"
import { Box, Button, Flex, Slide, useDisclosure } from "@chakra-ui/react"
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons"

import {
  Nav,
  NavSecondary,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout/"
import { Logo } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"

const Toggle = (props) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Box
      className="mobile-nav"
      display={["inline-block", "inline-block", "inline-block", "none"]}
      cursor="pointer"
      position="relative"
      {...props}
    >
      <Button
        onClick={onToggle}
        bg="transparent"
        color="white"
        variant="link"
        zIndex={200}
        _hover={{
          background: "transparent",
          border: "none",
          outline: "none",
        }}
        _focus={{
          background: "transparent",
          border: "none",
          outline: "none",
        }}
      >
        {isOpen ? (
          <CloseIcon w={6} h={6} color="white" />
        ) : (
          <HamburgerIcon w={8} h={8} color="white" />
        )}
      </Button>

      <Slide in={isOpen} style={{ zIndex: 199 }}>
        <Box
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          height={`calc(100% + 100vh)`}
          width="100%"
          bgColor="primary"
          opacity="1"
          transition={{ opacity: "0.30s", visibility: "0.30s" }}
        >
          <Flex
            flexDirection="column"
            flexWrap="nowrap"
            padding="4em 1em 0 1em"
            width="100%"
            color="white"
            sx={{
              "*": {
                fill: "white",
              },
              li: {
                borderBottom: `rgb(255, 255, 255, 0.1)`,
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                py: 2,
                "&:hover": {
                  bg: "primaryHover",
                },
              },
              "li a": {
                color: "white",
                textDecoration: "none",
                fontSize: ["lg", "lg", "xl"],
              },
              "li svg": {
                display: "none",
              },
            }}
          >
            <Logo
              color="white"
              style={{
                paddingBottom: 36,
                maxWidth: "280px",
              }}
            />
            <Nav />
            <NavSecondary />
          </Flex>
        </Box>
      </Slide>
    </Box>
  )
}

export default Toggle
