import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Button, Text, Stack } from "@chakra-ui/react"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "../../utils/slick.css"
import "../../utils/slick-theme.css"

import {
  ArrowLeft,
  ArrowRight,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"

const HighlightedResearcher = () => {
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    padding: "3em",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  const data = useStaticQuery(graphql`
    {
      allDatoCmsCancerResearcher(sort: {order: ASC, fields: position}) {
        edges {
          node {
            slug
            roleTitle
            position
            portrait {
              gatsbyImageData
            }
            isHighlighted
            id
            fullName
            contentNode {
              childMarkdownRemark {
                html
              }
            }
            callout
          }
        }
      }
    }
  `)
  return (
    <Slider {...settings}>
      {data.allDatoCmsCancerResearcher.edges.map(({ node: researcher }) => (
        <Box key={researcher.id} px={[6, 6, 9]}>
          <Stack direction={["column", "column", "row"]} bg="primary">
            <Box
              width={["full", "full", "1/2"]}
              sx={{
                ".gatsby-image-wrapper-constrained": { display: "block" },
              }}
            >
              <GatsbyImage
                image={researcher.portrait.gatsbyImageData}
                alt={`${researcher.fullName}'s portrait`}
                sx={{
                  objectFit: "cover",
                }}
              />
            </Box>
            <Stack
              width={["full", "full", "1/2"]}
              alignSelf="center"
              direction="column"
            >
              <Box width="full" color="white" p={[6]}>
                <h3>{researcher.fullName}</h3>

                <Text color="white">{researcher.callout}</Text>
                <Link
                  to={`/research/researchers/${researcher.slug}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <Button as="span" variant="outline">
                    Learn More
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Box>
      ))}
    </Slider>
  )
}

export default HighlightedResearcher
