import React from "react"
import { Box, Link as LinkExt, SimpleGrid } from "@chakra-ui/react"
import { Link } from "gatsby"

import { uniqueId } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalendarCheck,
  faChevronRight,
  faCommentMedical,
  faHandHoldingMedical,
  faHandsHeart,
  faHospitalUser,
  faMapMarkerAlt,
  faNotesMedical,
  faPodcast,
  faChild,
  faUsersMedical,
} from "@fortawesome/pro-regular-svg-icons"

import {
  Container,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  LabelIcon,
  PageLabel,
  SectionHeading,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const HomeQuickLinks = () => {
  return (
    <Section id="quicklinks" bg="lightgray">
      {/* Quick Links */}
      <Container>
        <Box width="full" textAlign="center">
          <SectionHeading>For your Convenience</SectionHeading>
        </Box>

        <Box width="full">
          <SimpleGrid
            columns={[1, 1, 2, 3]}
            spacing={[3, 3, 6]}
            sx={{
              a: { textDecoration: "none" },
              "a:hover": { textDecoration: "none" },
            }}
          >
            <Box key={`${uniqueId("card_id_")}`}>
              <Link to="/patients-visitors#cancers">
                <PageLabel lineHeight={1.15}>
                  <LabelIcon title="Cancers We Treat">
                    <FontAwesomeIcon
                      icon={faHandHoldingMedical}
                      className="fa-lg"
                    />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>
            <Box key={`${uniqueId("card_id_")}`}>
              <Link to="/patients-visitors#services">
                <PageLabel lineHeight={1.15}>
                  <LabelIcon title="Treatments & Services">
                    <FontAwesomeIcon icon={faUsersMedical} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>
            <Box key={`${uniqueId("card_id_")}`}>
              <LinkExt href="https://www.hsc.wvu.edu/ctru/current-clinical-trials/">
                <PageLabel lineHeight={1.15}>
                  <LabelIcon title="Clinical Trials">
                    <FontAwesomeIcon icon={faNotesMedical} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </LinkExt>
            </Box>

            <Box key={`${uniqueId("card_id_")}`}>
              <Link to="/patients-visitors/resources">
                <PageLabel lineHeight={1.15}>
                  <LabelIcon title="Patient & Visitor Resources">
                    <FontAwesomeIcon
                      icon={faCommentMedical}
                      className="fa-lg"
                    />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>
            <Box key={`${uniqueId("card_id_")}`}>
              <LinkExt href="https://childrens.wvumedicine.org/">
                <PageLabel lineHeight={1.15}>
                  <LabelIcon title="Pediatric Cancer">
                    <FontAwesomeIcon icon={faChild} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </LinkExt>
            </Box>
            <Box key={`${uniqueId("card_id_")}`}>
              <Link to="/about-us/programs/mobile-cancer-screening-program">
                <PageLabel lineHeight={1.15}>
                  <LabelIcon title="Mobile Cancer Screening Program">
                    <FontAwesomeIcon icon={faHandsHeart} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>
            <Box key={`${uniqueId("card_id_")}`}>
              <LinkExt href="https://wvumedicine.org/online-appointment-primary-care-physician/">
                <PageLabel lineHeight={1.15}>
                  <LabelIcon title="Appointments">
                    <FontAwesomeIcon icon={faCalendarCheck} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </LinkExt>
            </Box>
            <Box key={`${uniqueId("card_id_")}`}>
              <Link to="/about-us#locations">
                <PageLabel lineHeight={1.15}>
                  <LabelIcon title="Locations">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>
            <Box key={`${uniqueId("card_id_")}`}>
              <Link to="/for-medical-professionals/refer-patient">
                <PageLabel lineHeight={1.15}>
                  <LabelIcon title="Refer a Patient">
                    <FontAwesomeIcon icon={faHospitalUser} className="fa-lg" />
                  </LabelIcon>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
                </PageLabel>
              </Link>
            </Box>
          </SimpleGrid>
        </Box>
      </Container>
    </Section>
  )
}
export default HomeQuickLinks
