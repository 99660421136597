import React from "react"
import { Box, Button, Stack } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "../../utils/slick.css"
import "../../utils/slick-theme.css"

import {
  ArrowLeft,
  ArrowRight,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"

const DonorStories = () => {
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    padding: "3em",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  const data = useStaticQuery(graphql`
    query donorStories {
      allDatoCmsCancerDonor(filter: { meta: { status: { eq: "published" } } }) {
        edges {
          node {
            id
            fullName
            callout
            contentNode {
              childMarkdownRemark {
                html
              }
            }
            position
            slug
            isHighlighted
            portrait {
              gatsbyImageData
            }
            meta {
              status
            }
          }
        }
      }
    }
  `)
  return (
    <Slider {...settings}>
      {data.allDatoCmsCancerDonor.edges.map(({ node: story }) => (
        <Box key={story.id} px={[6, 6, 9]}>
          <Stack direction={["column", "column", "row"]} bg="accent.8">
            <Box width={["full", "full", "1/2"]}>
              <GatsbyImage
                image={story.portrait.gatsbyImageData}
                alt={`${story.title}'s portrait`}
                sx={{
                  objectFit: "cover",
                }}
              />
            </Box>
            <Stack
              width={["full", "full", "1/2"]}
              alignSelf="center"
              direction="column"
            >
              <Box width="full" color="white" p={[6]}>
                <h3>{story.fullName}</h3>
                <Box as="p" color="white">
                  {story.callout}
                </Box>
                <Link
                  to={`/giving/donor-stories/${story.slug}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <Button as="span" variant="outline">
                    Read Story
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Box>
      ))}
    </Slider>
  )
}

export default DonorStories
