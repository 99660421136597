import React from "react"
import { chakra, Box, SimpleGrid, Text, VStack } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Card } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const ChakraImg = chakra(GatsbyImage)

const LeadershipGrid = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCancerLeadership(
        sort: { fields: position, order: ASC  }
        filter: { meta: { status: { eq: "published" } } }
      ) {
        edges {
          node {
            slug
            position
            roleTitle
            credentials
            id
            fullName
            portrait {
              gatsbyImageData(width: 64)
            }
            meta {
              status
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <SimpleGrid
        columns={[1, 1, 2, 3]}
        spacing={6}
        wrap={["nowrap", "nowrap", "wrap"]}
      >
        {data.allDatoCmsCancerLeadership.edges.map(({ node: leader }) => (
          <Card p={[3, 3, 6]} textAlign="center" _hover={{ boxShadow: "xl" }}>
            <Link
              to={`/about-us/leadership/${leader.slug}`}
              style={{ textDecoration: "none" }}
            >
              <VStack flexDirection="column" height="full">
                <Box width="full">
                  <ChakraImg
                    image={leader.portrait.gatsbyImageData}
                    alt={leader.fullName}
                    borderRadius="full"
                    mx="auto"
                    width={16}
                    height={16}
                  />
                </Box>

                <Box width="full">
                  <Box as="h3">
                    {leader.fullName}
                    <span>
                      {leader.credentials && `, ${leader.credentials}`}
                    </span>
                  </Box>
                  <Text mb={0} fontSize={"sm"}>{leader.roleTitle && leader.roleTitle}</Text>
                </Box>
              </VStack>
            </Link>
          </Card>
        ))}
      </SimpleGrid>
    </>
  )
}

export default LeadershipGrid
