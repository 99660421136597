import React from "react"
import { Box, Button, Text, Stack } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "../../utils/slick.css"
import "../../utils/slick-theme.css"

import {
  ArrowLeft,
  ArrowRight,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"

const HighlightedTeam = () => {
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    padding: "3em",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  const data = useStaticQuery(graphql`
    query highlightedTeam {
      allDatoCmsCancerTeam(sort: { fields: position, order: ASC }) {
        edges {
          node {
            contentNode {
              childMarkdownRemark {
                html
                excerpt(format: PLAIN, truncate: false, pruneLength: 140)
              }
            }
            heroImage {
              gatsbyImageData
            }
            slug
            title
            introContent
            meta {
              status
            }
          }
        }
      }
    }
  `)
  return (
    <Slider {...settings}>
      {data.allDatoCmsCancerTeam.edges.map(({ node: team }) => (
        <Box key={team.id} px={[6, 6, 9]}>
          <Stack direction={["column", "column", "row"]} bg="primary">
            <Box
              width={["full", "full", "1/2"]}
              sx={{
                ".gatsby-image-wrapper-constrained": { display: "block" },
              }}
            >
              <GatsbyImage
                image={team.heroImage.gatsbyImageData}
                alt={`${team.title}'s team`}
                sx={{
                  objectFit: "cover",
                }}
              />
            </Box>
            <Stack
              width={["full", "full", "1/2"]}
              alignSelf="center"
              direction="column"
            >
              <Box width="full" color="white" p={[6]}>
                <h3>{team.title}</h3>
                <Box as="p" color="white">
                  {team.introContent}
                </Box>
                <Text color="white">{team.callout}</Text>
                <Link
                  to={`/about-us/teams/${team.slug}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <Button as="span" variant="outline">
                    Learn More
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Box>
      ))}
    </Slider>
  )
}

export default HighlightedTeam
