import React from "react"
import { Box } from "@chakra-ui/react"

const NavLink = (props) => {
  return (
    <Box
      as="li"
      color="inherit"
      display={["block", "block", "block", "inline-block"]}
      fontFamily="body"
      px={"6px"}
      m={0}
      sx={{
        "a:hover": {
          textDecoration: "none",
        },
      }}
      {...props}
    >
      {props.children}
    </Box>
  )
}

export default NavLink
