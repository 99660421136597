import React from "react"
import { Box, Button, Stack } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import "../../utils/slick.css"
import "../../utils/slick-theme.css"

const ResourcesCard = () => {
  const data = useStaticQuery(graphql`
    query resourcesCard {
      datoCmsCancerResource {
        id
        title
        slug
        heroImage {
          gatsbyImageData
        }
        introContentNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)
  return (
    <>
      <Stack direction={["column", "column", "row"]} bg="primary">
        <Box
          width={["full", "full", "1/2"]}
          sx={{
            ".gatsby-image-wrapper-constrained": { display: "block" },
          }}
        >
          <GatsbyImage
            image={data.datoCmsCancerResource.heroImage.gatsbyImageData}
            alt={data.datoCmsCancerResource.title}
            sx={{
              objectFit: "cover",
            }}
          />
        </Box>
        <Stack
          width={["full", "full", "1/2"]}
          alignSelf="center"
          direction="column"
        >
          <Box width="full" color="white" p={[6]}>
            <h3>{data.datoCmsCancerResource.title}</h3>
            <Box
              dangerouslySetInnerHTML={{
                __html:
                  data.datoCmsCancerResource.introContentNode
                    .childMarkdownRemark.html,
              }}
              sx={{ "*": { color: "white" } }}
            />

            <Link
              to={`/patients-visitors/${data.datoCmsCancerResource.slug}`}
              style={{ color: "white", textDecoration: "none" }}
            >
              <Button as="span" variant="outline">
                Learn More
              </Button>
            </Link>
          </Box>
        </Stack>
      </Stack>
    </>
  )
}

export default ResourcesCard
