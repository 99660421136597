import React from "react"
import { Box, Button, SimpleGrid, Stack, VStack } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"
import { Card } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const InsideInstitute = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCancerInsideInstitute(
        limit: 3
        sort: { fields: position, order: ASC }
      ) {
        edges {
          node {
            slug
            title
            id
            intro
            heroImage {
              gatsbyImageData(width: 800)
            }
            contentNode {
              childMarkdownRemark {
                excerpt(format: PLAIN, truncate: false, pruneLength: 140)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {/*get first element from array*/}
      {data.allDatoCmsCancerInsideInstitute.edges
        .map(({ node }) => (
          <motion.div whileHover={{ scale: 1.015 }} key={node.id}>
            <Card
              boxShadow="lg"
              mb={6}
              bg="accent.8"
              _hover={{ boxShadow: "xl" }}
              width="full"
            >
              <Stack direction={["column", "column", "row"]} width="full">
                <Box
                  width={["full", "full", "1/2"]}
                  sx={{
                    ".gatsby-image-wrapper-constrained": { display: "block" },
                  }}
                >
                  <GatsbyImage
                    image={node.heroImage.gatsbyImageData}
                    alt={node.title}
                  />
                </Box>

                <VStack width={["full", "full", "1/2"]} alignSelf="center">
                  <Box width="full" height="full" color="white" p={6}>
                    <Box as="h3">{node.title}</Box>
                    <Box as="p" color="white">
                      {node.intro}
                    </Box>

                    <Link
                      to={`/about-us/inside-the-institute/${node.slug}`}
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <Button as="span" variant="outline" colorScheme="white">
                        Read More
                      </Button>
                    </Link>
                  </Box>
                </VStack>
              </Stack>
            </Card>
          </motion.div>
        ))
        .shift()}

      <SimpleGrid columns={[1, 1, 2]} spacing={6}>
        {/*get the rest of elements from array*/}
        {data.allDatoCmsCancerInsideInstitute.edges
          .map(({ node }) => (
            <motion.div whileHover={{ scale: 1.015 }} key={node.id}>
              <Card
                boxShadow="lg"
                width={["full", "full", "1/2"]}
                height="full"
                _hover={{ boxShadow: "xl" }}
              >
                <VStack
                  flexDirection="column"
                  justifyContent="space-between"
                  height="full"
                >
                  <Box width="full">
                    <GatsbyImage
                      image={node.heroImage.gatsbyImageData}
                      alt={node.title}
                    />
                  </Box>

                  <Box p={6} width="full">
                    <Box as="h3">{node.title}</Box>
                    <Box as="p" mb={0}>
                      {node.intro}
                    </Box>
                  </Box>

                  <VStack
                    height="full"
                    width="full"
                    justifyContent="flex-end"
                    p={6}
                    pt={0}
                    alignItems="flex-start"
                  >
                    <Link
                      to={`/about-us/inside-the-institute/${node.slug}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button as="span" variant="outline" colorScheme="blue">
                        Read More
                      </Button>
                    </Link>
                  </VStack>
                </VStack>
              </Card>
            </motion.div>
          ))
          .slice(1)}
      </SimpleGrid>
    </>
  )
}

export default InsideInstitute
