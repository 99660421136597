import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Button, Flex, Link as LinkExt, Stack } from "@chakra-ui/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Card } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const AcademicPrograms = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCancerAcademicProgram {
        edges {
          node {
            id
            slug
            title
            heroImage {
              gatsbyImageData
            }
            sourceLink
            introContent
          }
        }
      }
    }
  `)
  return (
    <Box
      sx={{
        ".cards-list": {
          ".cards-list--item:nth-of-type(even)": {
            ".cards-list--item--child": {
              flexDirection: "row-reverse",
            },
          },
        },
      }}
    >
      <Stack direction={["column"]} spacing={6} className="cards-list">
        {data.allDatoCmsCancerAcademicProgram.edges.map(({ node: program }) => (
          <Card
            key={program.id}
            boxShadow="lg"
            width="full"
            className="cards-list--item"
          >
            <Flex
              flexWrap={["wrap", "wrap", "nowrap"]}
              className="cards-list--item--child"
              width="full"
            >
              <Box width={["full", "full", "1 / 2"]}>
                <GatsbyImage
                  image={program.heroImage.gatsbyImageData}
                  alt={program.title}
                  style={{ display: "block" }}
                />
              </Box>
              <Flex
                flexDirection="column"
                justifyContent="center"
                width={["full", "full", "1 / 2"]}
              >
                <Box width="full" p={6}>
                  <Box as="h3">{program.title}</Box>
                  <Box as="p">{program.introContent}</Box>
                  <LinkExt
                    href={program.sourceLink}
                    isExternal
                    style={{ textDecoration: "none" }}
                  >
                    <Button as="span" variant="outline" colorScheme="blue">
                      Learn More
                    </Button>
                  </LinkExt>
                </Box>
              </Flex>
            </Flex>
          </Card>
        ))}
      </Stack>
    </Box>
  )
}

export default AcademicPrograms
