import React from "react"
import { Box, Button, Text, Stack } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Slider from "react-slick"
import "../../utils/slick.css"
import "../../utils/slick-theme.css"

import {
  ArrowLeft,
  ArrowRight,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"

const HighlightedProvider = () => {
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    padding: "3em",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  const data = useStaticQuery(graphql`
    query highlightedProvider {
      allDatoCmsCancerProvider(
        filter: {
          isHighlighted: { eq: true }
          meta: { status: { eq: "published" } }
        }
        sort: {order: ASC, fields: position}
      ) {
        edges {
          node {
            callout
            position
            contentNode {
              childMarkdownRemark {
                html
              }
            }
            fullName
            id
            isHighlighted
            meta {
              status
            }
            portrait {
              gatsbyImageData
            }
            roleTitle
            slug
          }
        }
      }
    }
  `)
  return (
    <Slider {...settings}>
      {data.allDatoCmsCancerProvider.edges.map(
        ({ node: highlighted }) => (
          <Box key={highlighted.id} px={[6, 6, 9]}>
            <Stack direction={["column", "column", "row"]} bg="primary">
              <Box
                width={["full", "full", "1/2"]}
                sx={{
                  ".gatsby-image-wrapper-constrained": { display: "block" },
                }}
              >
                <GatsbyImage
                  image={highlighted.portrait.gatsbyImageData}
                  alt={`${highlighted.fullName}'s portrait`}
                  sx={{
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Stack
                width={["full", "full", "1/2"]}
                alignSelf="center"
                direction="column"
              >
                <Box width="full" color="white" p={[6]}>
                  <h3>{highlighted.fullName}</h3>
                  <Text color="white">{highlighted.callout}</Text>
                  <Link
                    to={`/about-us/highlighted-provider/${highlighted.slug}`}
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Button as="span" variant="outline">
                      Read Provider's Story
                    </Button>
                  </Link>
                </Box>
              </Stack>
            </Stack>
          </Box>
        )
      )}
    </Slider>
  )
}

export default HighlightedProvider
