import React from "react"
import { Box, Button, Stack } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const HighlightedTreatment = () => {
  const data = useStaticQuery(graphql`
    query highlightedTreatment {
      allDatoCmsWvumService(filter: { highlighted: { eq: true } }) {
        edges {
          node {
            id
            highlighted
            title
            descriptionNode {
              childMarkdownRemark {
                excerpt(format: PLAIN, truncate: false, pruneLength: 140)
              }
            }
            image {
              gatsbyImageData
            }
            locations {
              ... on DatoCmsInstitute {
                id
                instituteName
              }
            }
            slug
          }
        }
      }
      datoCmsInstitute(id: { eq: "DatoCmsInstitute-3541459-en" }) {
        instituteName
        id
      }
    }
  `)

  const HighlightedServices = data.allDatoCmsWvumService.edges.map(
    ({ node: service }) => {
      return service
    }
  )

  const ServiceLocations = HighlightedServices.map(({ locations }) => {
    return locations
  })

  const ServiceLocation = ServiceLocations[0].map((location) => {
    return location.id
  })

  //console.log(ServiceLocation)

  return (
    <>
      {data.allDatoCmsWvumService.edges.map(({ node: service }) => (
        <>
          {service.locations
            .map(({ id }) => (
              <>
                {id === "DatoCmsInstitute-3541459-en" && (
                  <Box key={service.id}>
                    <Stack
                      direction={["column", "column", "row"]}
                      bg="accent.6"
                    >
                      <Box
                        width={["full", "full", "1/2"]}
                        sx={{
                          ".gatsby-image-wrapper-constrained": {
                            display: "block",
                          },
                        }}
                      >
                        <GatsbyImage
                          image={service.image[0].gatsbyImageData}
                          alt={`${service.title}`}
                          sx={{
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                      <Stack
                        width={["full", "full", "1/2"]}
                        alignSelf="center"
                        direction="column"
                      >
                        <Box width="full" color="white" p={[6]}>
                          <Box>
                            <h3>{service.title}</h3>
                            <Box
                              pb={6}
                              dangerouslySetInnerHTML={{
                                __html:
                                  service.descriptionNode.childMarkdownRemark
                                    .excerpt,
                              }}
                            />
                            <Link
                              to={`/patients-visitors/services/${service.slug}`}
                              style={{ color: "white", textDecoration: "none" }}
                            >
                              <Button as="span" variant="outline">
                                Learn More
                              </Button>
                            </Link>
                          </Box>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </>
            ))
            .slice(0, 1)}
        </>
      ))}
    </>
  )
}

export default HighlightedTreatment
