import React from "react"
import { chakra, Box } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"

import { PageLabel } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const GatsbyLink = chakra(Link)

const CancersWeTreat = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCancerTreat(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            title
            subtitle
            slug
          }
        }
      }
    }
  `)

  return (
    <>
      {data.allDatoCmsCancerTreat.edges.map(({ node: cancer }) => (
        <React.Fragment key={cancer.id}>
          <GatsbyLink
            to={`/patients-visitors/cancers-we-treat/${cancer.slug}`}
            color="text"
            textDecoration="none"
          >
            <PageLabel
              borderLeftColor="blue.500"
              borderLeftWidth={6}
              p={3}
              m={0}
              width="full"
              height="full"
            >
              <Box as="h3" lineHeight={1} mb={0}>
                {cancer.title}
                {cancer.subtitle && (
                  <Box as="span" fontSize="md" lineHeight={1}>
                    <br />
                    {cancer.subtitle}
                  </Box>
                )}
              </Box>
              <FontAwesomeIcon icon={faChevronRight} className="fa-lg" />
            </PageLabel>
          </GatsbyLink>
        </React.Fragment>
      ))}
    </>
  )
}

export default CancersWeTreat
