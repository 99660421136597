import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Button, Flex, Link as LinkExt, Stack } from "@chakra-ui/react"
import { GatsbyImage } from "gatsby-plugin-image"

import { Card } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const ResearchCards = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCancerResearchPage(sort: { fields: position }) {
        edges {
          node {
            id
            title
            slug
            externalLink
            position
            introContent
            heroImage {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return (
    <Box
      pt={12}
      sx={{
        ".cards-list": {
          ".cards-list--item:nth-of-type(even)": {
            ".cards-list--item--child": {
              flexDirection: "row-reverse",
            },
          },
        },
      }}
    >
      <Stack Stack direction={["column"]} spacing={6} className="cards-list">
        {data.allDatoCmsCancerResearchPage.edges.map(({ node: item }) => (
          <Card
            key={item.id}
            boxShadow="lg"
            width="full"
            className="cards-list--item"
          >
            <Flex
              flexWrap={["wrap", "wrap", "nowrap"]}
              className="cards-list--item--child"
              width="full"
            >
              <Box width={["full", "full", "1 / 2"]}>
                <GatsbyImage
                  image={item.heroImage.gatsbyImageData}
                  alt={item.title}
                  style={{ display: "block" }}
                />
              </Box>
              <Flex
                flexDirection="column"
                justifyContent="center"
                width={["full", "full", "1 / 2"]}
              >
                <Box width="full" p={6}>
                  <Box as="h3">{item.title}</Box>
                  <Box as="p">{item.introContent}</Box>
                  {item.externalLink ? (
                    <LinkExt
                      href={item.externalLink}
                      style={{ textDecoration: "none" }}
                    >
                      <Button as="span" variant="outline" colorScheme="blue">
                        Learn More
                      </Button>
                    </LinkExt>
                  ) : (
                    <Link to={item.slug} style={{ textDecoration: "none" }}>
                      <Button as="span" variant="outline" colorScheme="blue">
                        Learn More
                      </Button>
                    </Link>
                  )}
                </Box>
              </Flex>
            </Flex>
          </Card>
        ))}
      </Stack>
    </Box>
  )
}

export default ResearchCards
