import React, { useState, useEffect } from "react"
import { Box } from "@chakra-ui/react"
import DatoCmsSearch from "datocms-search/dist/datocms-search.widget"

import { SearchButton } from "./"

const SearchContainer = (props) => {
  useEffect(() => {
    const client = new DatoCmsSearch(
      "0888cf19d43eda1e6d2537399a7975",
      "production"
    ).addWidget("#search")
    const resultsLabel = document.querySelector(
      ".datocms-widget__no-results__label"
    )
    if (resultsLabel) {
      resultsLabel.innerHTML = "WVU Cancer Institute"
    }
  })
  const [isSearchBoxOn, setSearchOn] = useState(false)
  const toggleSearchBox = () => setSearchOn(!isSearchBoxOn)

  return (
    <Box
      sx={{
        position: "absolute",
        display: "block",
        top: 0,
        left: 0,
        width: "full",
        zIndex: 900,
      }}
      {...props}
    >
      <SearchButton
        toggleSearchBox={toggleSearchBox}
        isSearchBoxOn={isSearchBoxOn}
      />

      <Box
        id="search"
        sx={{
          display: isSearchBoxOn ? "block" : "none",
          position: "relative",
          pt: [9, 9, 12],
          backgroundColor: "blue.50",
          heigth: "full",
          width: "full",
          zIndex: 20,
        }}
      />
    </Box>
  )
}

export default SearchContainer
