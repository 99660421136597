import React from "react"
import { UnorderedList } from "@chakra-ui/react"
import { Link } from "gatsby"

import { NavLink } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const Nav = (props) => (
  <UnorderedList
    as="ul"
    m={0}
    {...props}
    sx={{
      a: { color: "gray.600", textDecoration: "none" },
      "a.active": { color: "black" },
    }}
  >
    <NavLink>
      <Link to="/about-us" activeClassName="active">
        About Us
      </Link>
    </NavLink>
    <NavLink>
      <Link to="/patients-visitors" activeClassName="active">
        Patients & Visitors
      </Link>
    </NavLink>
    <NavLink>
      <Link
        to="/about-us/inside-the-institute/cancer-prevention-and-control"
        activeClassName="active"
      >
        Outreach
      </Link>
    </NavLink>
    <NavLink>
      <Link to="/research" activeClassName="active">
        Research
      </Link>
    </NavLink>
    <NavLink>
      <Link to="/for-medical-professionals" activeClassName="active">
        For Medical Professionals
      </Link>
    </NavLink>
    <NavLink>
      <Link to="/contact-us" activeClassName="active">
        Contact Us
      </Link>
    </NavLink>
  </UnorderedList>
)

export default Nav
