module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: `WVU Cancer Institute`, // Navigation and Site Title
  siteTitleAlt: `WVU Cancer Institute`, // Alternative Site title for SEO
  siteShortName: "WVU Cancer Institute", // Short name
  siteUrl: "https://cancer.wvumedicine.org", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteLogo: "/src/images/favicon.png", // Used for SEO and manifest
  siteImage:
    "https://www.datocms-assets.com/30187/1625250951-wvuci-news-placeholder.gif", // Used for SEO and manifest
  siteDescription:
    "Improving the health of West Virginians and all we serve through excellence in patient care, research, and education.",

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: "@wvucancer", // Twitter Username
  facebook: "https://www.facebook.com/wvucancer", // Facebook link
  //instagram: "https://www.instagram.com/WVUMedicine/", // Instagram link
  twitter: "https://twitter.com/wvucancer", // Twitter link
  youTube: "http://www.youtube.com/user/WVUHealthcare", // YouTube link
  ogSiteName: "wvucancer", // Facebook Site Name
  ogLanguage: "en", // Facebook Language

  phone: "877-427-2894",
  address: `1 Medical Center Drive \n
  Morgantown, WV 26506`,
  email: "ask@wvumedicine.org",

  // Manifest and Progress color
  themeColor: "#ccc",
  backgroundColor: "#fff",
}
