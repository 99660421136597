import React from "react"
import { Box } from "@chakra-ui/react"

function HeroGradient(props) {
  return (
    <Box
      position="absolute"
      zIndex={2}
      bottom={0}
      left={0}
      right={0}
      top={0}
      width="full"
      height="full"
      bgGradient="linear(to-t, blackAlpha.700, transparent)"
      {...props}
    />
  )
}

export default HeroGradient
