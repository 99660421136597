import React from "react"
import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Stack,
  VStack,
} from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import { Card } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { format } from "date-fns"
import fetcher from "../../utils/fetcher"
import useSWR from "swr"

const BonniesNewsGrid = () => {
  const { data, error } = useSWR(
    `https://mobile.wvumedicine.org/wayfinder/api/news/story/featured/wvu-medicine/Bonnies-Bus/3`,
    fetcher
  )

  if (error) return <div>failed to load</div>

  if (!data)
    return (
      <Box width="full" height="full">
        <Center width="full" height="full">
          <Spinner size="xl" color="primary" />
        </Center>
      </Box>
    )

  return (
    <Box sx={{ width: ["full"] }}>
      <Stack direction={["column", "column", "row"]} spacing={6}>
        {data.map(({ ...story }) => (
          <Card key={story.guid} width={["full", "full", "1/3"]} boxShadow="lg">
            <VStack
              flexDirection="column"
              justifyContent="space-between"
              height="full"
            >
              <Box width="full">
                {story.image ? (
                  <img
                    src={story.image}
                    alt={story.headline}
                    width="600"
                    heigth="337"
                    loading="lazy"
                  />
                ) : (
                  <StaticImage
                    src="../../images/wvuci-news-placeholder.jpg"
                    alt="news image"
                    width={600}
                    heigth={337}
                  />
                )}
              </Box>

              <Box p={6} pb={0}>
                <Box as="p" fontSize="sm" fontWeight="bold" mb={0}>
                  {`${format(new Date(story.newsDate), "MM/dd/yyyy")}`}
                </Box>
                <h3>{story.headline}</h3>
              </Box>

              <Flex
                flexDirection="column"
                height="full"
                width="full"
                justifyContent="flex-end"
                p={6}
                pt={0}
                alignItems="flex-start"
              >
                <a
                  href={`https://wvumedicine.org/news-feed/news-article/WVU-Medicine/Bonnies-Bus/${story.uniqueHeadline}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button as="span" variant="outline" colorScheme="blue">
                    Full Story
                  </Button>
                </a>
              </Flex>
            </VStack>
          </Card>
        ))}
      </Stack>
    </Box>
  )
}

export default BonniesNewsGrid
