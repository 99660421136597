import React from "react"
import { Box } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"

import { PageLabel } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const Services = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsWvumService(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            title
            slug
            locations {
              ... on DatoCmsInstitute {
                id
                instituteName
              }
              ... on DatoCmsHospital {
                id
                hospitalName
              }
              ... on DatoCmsCenter {
                id
                centerName
              }
              ... on DatoCmsClinic {
                id
                clinicName
              }
            }
          }
        }
      }
    }
  `)
  const services = data.allDatoCmsWvumService.edges.map(({ node: service }) => {
    return { service }
  })

  return (
    <>
      {services.map(({ service }) => (
        <React.Fragment key={service.id}>
          {service.locations.map((location) => {
            return (
              <React.Fragment key={location.id}>
                {location.id.includes("DatoCmsInstitute-3541459-en") ? (
                  <Link
                    to={`/patients-visitors/services/${service.slug}`}
                    sx={{ color: "text", textDecoration: "none" }}
                  >
                    <PageLabel
                      borderLeftColor="blue.500"
                      borderLeftWidth={6}
                      p={3}
                      m={0}
                      width="full"
                      height="full"
                    >
                      <Box as="h3" lineHeight={1} mb={0}>
                        {service.title}
                      </Box>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="fa-lg"
                      />
                    </PageLabel>
                  </Link>
                ) : null}
              </React.Fragment>
            )
          })}
        </React.Fragment>
      ))}
    </>
  )
}

export default Services
