import React from "react"
import {
  Box,
  Button,
  Center,
  Link as LinkExt,
  Spinner,
  Stack,
} from "@chakra-ui/react"
import useSWR from "swr"
import fetcher from "../../utils/fetcher"
import { Card } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const Calendar = (props) => {
  const { data, error } = useSWR(
    `https://api.factmaven.com/xml-to-json/?xml=https://calendar.wvu.edu/site/wvucancerinstitute/page/xml/?id=a72940cc-666a-4cef-a2b1-9fce39df4333`,
    fetcher
  )

  if (error) return <div>Failed to load events</div>

  if (!data)
    return (
      <Box width="full" height="full">
        <Center width="full" height="full">
          <Spinner size="xl" color="primary" />
        </Center>
      </Box>
    )

  const Events = data.events.event
  //console.log(Events)

  return (
    <>
      <Stack direction={["column", "column", "row"]} spacing={6}>
        {Events.map(({ name, url, "local-start-date": localStartSate, id }) => (
          <Card
            key={id}
            boxShadow="lg"
            borderTopColor="accent.7"
            borderTopWidth={4}
            width={["full", "full", "1/3"]}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              height="full"
              p={6}
            >
              <Box>
                <Box as="p" fontSize="sm" fontWeight="bold" mb={0}>
                  {localStartSate}
                </Box>
                <Box as="h3">{name}</Box>
              </Box>

              <LinkExt href={url} sx={{ _hover: { textDecoration: "none" } }}>
                <Button as="span" variant="outline" colorScheme="blue">
                  Event Details
                </Button>
              </LinkExt>
            </Stack>
          </Card>
        )).slice(0, 3)}
      </Stack>
    </>
  )
}

export default Calendar
