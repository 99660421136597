import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, UnorderedList, ListItem } from "@chakra-ui/react"

const ProgramsListing = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCancerProgram(
        sort: { fields: title, order: ASC }
        filter: { meta: { status: { eq: "published" } } }
      ) {
        edges {
          node {
            id
            title
            slug
            meta {
              status
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Box as="h4" mt={6}>
        Programs
      </Box>
      <UnorderedList listStyleType="none" m={0} p={0}>
        {data.allDatoCmsCancerProgram.edges.map(({ node: program }) => (
          <ListItem
            key={program.id}
            borderBottomColor="blackAlpha.200"
            borderBottomStyle="solid"
            borderBottomWidth={1}
            m={0}
            py={1}
            sx={{ a: { textDecoration: "none" } }}
          >
            <Link to={`/about-us/programs/${program.slug}`}>
              {program.title}
            </Link>
          </ListItem>
        ))}
      </UnorderedList>
    </>
  )
}

export default ProgramsListing
