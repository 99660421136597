import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { GatsbyImage } from "gatsby-plugin-image"

import { PageLabel } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const Liasons = ({ page }) => {
  return (
    <Box width="full">
      <Flex flexWrap="wrap" justifyContent="center">
        {page.liasons.map(({ id, name, email, phoneNumber, photo, title }) => (
          <PageLabel
            key={id}
            width={["full", "full", 1 / 3]}
            mx={[0, 0, 3]}
            my={3}
            p={3}
          >
            <Flex
              justifyContent="center"
              width="full"
              flexWrap="nowrap"
              flexDirection="column"
              textAlign="center"
            >
              <Box
                borderRadius="full"
                height={24}
                width={24}
                textAlign="center"
                mx="auto"
                mb={3}
                display="block"
                overflow="hidden"
              >
                <GatsbyImage
                  image={photo.gatsbyImageData}
                  alt={`${name}'s portrait`}
                  sx={{
                    objectFit: "cover",
                  }}
                />
              </Box>

              <Box as="h3" mb={0}>
                {name}
              </Box>
              <Box as="p" mb={0}>
                {title ? (
                  <Box>
                    {title} <br />
                  </Box>
                ) : null}
                <a href={`mailto:${email}`}>{email}</a> <br />
                Phone: <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </Box>
            </Flex>
          </PageLabel>
        ))}
      </Flex>
    </Box>
  )
}
export default Liasons
