import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../config/website"

const SEO = (props) => {
  const title = config.siteTitle
  const description = config.siteDescription
  const image = config.siteImage
  const blogURL = config.siteUrl + config.pathPrefix
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "website",
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
    },
  ]
  return (
    <Helmet {...props}>
      <html lang={config.siteLanguage} />
      <title>
        {props.title
          ? `${props.title} | ${title}`
          : `${props.page_title} | ${title}`}
      </title>
      <meta name="msapplication-TileColor" content={config.backgroundColor} />
      <meta name="msapplication-config" content="browserconfig.xml" />
      <meta
        name="description"
        content={props.description ? `${props.description}` : `${description}`}
      />
      <meta
        name="image"
        content={props.image ? `${props.image}` : `${image}`}
      />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      <meta property="og:locale" content={config.ogLanguage} />
      <meta property="og:site_name" content={config.ogSiteName} />
      <meta
        property="og:title"
        content={
          props.title
            ? `${props.title} | ${title}`
            : `${props.page_title} | ${title}`
        }
      />
      <meta
        property="og:description"
        content={props.description ? `${props.description}` : `${description}`}
      />
      <meta
        property="og:image"
        content={props.image ? `${props.image}` : `${image}`}
      />
    </Helmet>
  )
}

export default SEO
