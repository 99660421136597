import React from "react"
import { Box, SimpleGrid } from "@chakra-ui/react"

import { QuickLink } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const QuickLinks = ({ page }) => {
  return (
    <Box width="full">
      <SimpleGrid
        columns={[1, 1, 2, 3]}
        spacing={[3, 3, 6]}
        sx={{ a: { textDecoration: "none", lineHeight: 1.15, pr: 3 } }}
      >
        {page.quicklinks.map(
          ({ id, linkTitle, externalLinkUrl, internalLinkUrl }) => (
            <QuickLink
              key={id}
              title={linkTitle}
              to={externalLinkUrl ? externalLinkUrl : internalLinkUrl}
            />
          )
        )}
      </SimpleGrid>
    </Box>
  )
}
export default QuickLinks
