import React from "react"
import useSWR from "swr"
import { Box, Center, Flex, SimpleGrid, Spinner } from "@chakra-ui/react"

import fetcher from "../../utils/fetchercors"

const MembershipDirectory = (props) => {
  const { data, error } = useSWR(
    `https://sole.hsc.wvu.edu/Apps/Directory/api/v1/users?departmentIds=2092&sortBy=position`,
    fetcher
  )

  if (error) return <div>failed to load</div>

  if (!data)
    return (
      <Box width="full" height="full">
        <Center width="full" height="full">
          <Spinner size="xl" color="primary" />
        </Center>
      </Box>
    )

  // console.info(data)

  return (
    <SimpleGrid columns={[1, 1, 2, 3]} spacing={6}>
      {data.items.map(({ id, firstName, lastName, credentials, jobTitle }) => (
        <Flex
          key={id}
          alignItems="center"
          bg="white"
          justifyContent="center"
          flexWrap="wrap"
          width="full"
        >
          <Box as="p" mb={0} p={3} textAlign="center">
            <strong>
              {firstName} {lastName}
              {credentials.length > 0 && <Box as="span">, </Box>}
              {credentials &&
                credentials.map(({ credential }) => (
                  <Box as="span">{credential} </Box>
                ))}{" "}
            </strong>
            <br />
            {jobTitle}
          </Box>
        </Flex>
      ))}
    </SimpleGrid>
  )
}

export default MembershipDirectory
