import React from "react"
import { Box, Flex, Divider } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons"

import { Logo } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"
import {
  Container,
  FooterNav,
  MegaFooter,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

const Footer = ({ facebook, twitter, phone, address }) => {
  const d = new Date()
  const n = d.getFullYear()

  return (
    <Box as="footer" bg="lightgray" fontSize="sm" py={[6, 6, 9, 12]}>
      <Container>
        <Flex
          mx={[0, 0, 0, -6]}
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box mb={[2, 2, 0]} px={[0, 0, 0, 6]} width={[1 / 2, 1 / 2, 1 / 4]}>
            <Logo />
          </Box>
          <Box px={[0, 0, 0, 6]} width={["full", "full", 1 / 3, 1 / 4]}>
            <p>
              {address && address} <br />
              {phone && <a href={`tel:${phone}`}>{phone}</a>}
            </p>
          </Box>
          <Box px={[0, 0, 0, 6]} width={["full", "full", 1 / 3, 1 / 4]}></Box>
          <Box px={[0, 0, 0, 6]} width={["full", "full", "full", 1 / 4]}>
            <Box
              as="ul"
              textAlign="left"
              margin={0}
              sx={{
                display: "flex",
                listStyle: "none",
                alignItems: "center",
                li: {
                  display: "block",
                  fontSize: "1.5em",
                  marginLeft: "5px",
                  marginRight: "5px",
                },
                svg: {
                  color: "black",
                  minWidth: "40px",
                },
                img: {
                  minWidth: "28px",
                  maxWidth: "28px",
                  height: "28px!important",
                },
                "svg:hover ": {
                  color: "blue.500",
                },
              }}
            >
              {facebook && (
                <li>
                  <a href={facebook} aria-label="Facebook">
                    <img
                      src={"/images/facebook-f.svg"}
                      alt="Facebook Logo"
                      style={{ height: "28px" }}
                    />
                  </a>
                </li>
              )}
              {twitter && (
                <li>
                  <a href={twitter} aria-label="Twitter">
                    <img src={"/images/x-twitter.svg"} alt="X Logo" />
                  </a>
                </li>
              )}
              <li>
                <a
                  href="https://www.instagram.com/wvucancer/"
                  aria-label="Instagram"
                >
                  <img src={"/images/instagram.svg"} alt="Instagram Logo" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/wvucancer/"
                  aria-label="LinkedIn"
                >
                  <img src={"/images/linkedin-in.svg"} alt="LinkedIn Logo" />
                </a>
              </li>
            </Box>
          </Box>

          <Box width="full" px={[0, 0, 0, 6]}>
            <Divider my={3} borderColor="blackAlpha.200" />
          </Box>

          <FooterNav />

          <MegaFooter />

          <Box width="full" px={[0, 0, 0, 6]}>
            <Divider borderColor="blackAlpha.200" />
            <Box as="p" fontSize="sm" mt={3} mb={0}>
              &copy; {n} Copyright - West Virginia University Health System
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
