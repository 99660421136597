import React from "react"
import { chakra, Box, Button, Flex, Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import Slider from "react-slick"
import { uniqueId } from "lodash"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import {
  ArrowLeft,
  ArrowRight,
  Hero,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/"
import { Container } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout/"

//const bgImage = convertToBgImage(image)
const HeroImage = chakra(BackgroundImage)
const StyledArrowLeft = chakra(ArrowLeft)
const StyledArrowRight = chakra(ArrowRight)

function HomeSlideshow({ page }) {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: (
      <StyledArrowLeft boxSize={9} opacity={0.75} _hover={{ opacity: 1 }} />
    ),
    nextArrow: (
      <StyledArrowRight boxSize={9} opacity={0.75} _hover={{ opacity: 1 }} />
    ),
  }

  return (
    <Hero overflow="hidden" width="100%" height="full" position="relative">
      <Slider {...settings}>
        {page.slideshow.map(({ buttonText, slideContent, image, link }) => (
          <Box
            key={`${uniqueId("slide_id_")}`}
            sx={{
              maxHeight: "600px",
              objectPosition: "center",
              position: "relative",
              overflow: "hidden",
              width: "full",
            }}
          >
            <Box width="full" height="full" position="relative">
              <Box position="relative" height="full" width="full">
                <HeroImage
                  {...convertToBgImage(image.gatsbyImageData)}
                  minHeight={["400px", "400px", "600px"]}
                  width="full"
                  pt={24}
                  style={{
                    maxHeight: "600px",
                    objectPosition: "left",
                    zIndex: 0,
                  }}
                />
                <Container
                  position={["initial", "initial", "relative"]}
                  width="full"
                  height="full"
                  inset={0}
                  zIndex={1}
                >
                  <Flex
                    position="absolute"
                    background={`rgba(0,40,85,0.65)`}
                    left={0}
                    bottom={0}
                    width={["full", "full", "65%"]}
                    minHeight={["full", "full", "150px"]}
                    sx={{
                      clipPath: {
                        md: "polygon(0% 0%, 100% 0%, 85% 100%, 0% 100%)",
                      },
                      "*": { color: "white" },
                      a: { textDecoration: "none" },
                    }}
                    zIndex={5}
                  >
                    <Box py={[0, 0, 9]} px={[12]} alignSelf="center">
                      <Heading
                        as="p"
                        fontSize={["3xl", "3xl", "4xl"]}
                        fontWeight="normal"
                        color="white"
                      >
                        {slideContent}
                      </Heading>

                      {link && (
                        <Link to={link}>
                          <Button
                            as="span"
                            variant="outline"
                            colorScheme="white"
                            size={["sm"]}
                            fontFamily="body"
                            _hover={{ bg: "white", color: "primary" }}
                          >
                            {buttonText}
                          </Button>
                        </Link>
                      )}
                    </Box>
                  </Flex>
                </Container>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Hero>
  )
}

export default HomeSlideshow
