import React from "react"
import theme from "@giraldomac/gatsby-theme-wvumedicine/src/@chakra-ui/gatsby-plugin/theme"

const Logo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2005.6 180.7"
    fill="currentColor"
    className="logo"
    {...props}
  >
    <g fill={`${theme.colors.secondary}`} className="flyingwv">
      <path d="M186.5 3.9h-33.1c-5.8-.1-7.9 2.1-9.6 4.7L119.3 45 95.8 9.4 95 8.2s-.6-1-1.8-1.1c-1.1 0-1.8 1.1-1.8 1.1l-.8 1.2L67.9 45 42.7 8.6c-1.7-2.7-3.8-4.8-9.6-4.7H0v29.7h18.4c3.9 0 5.7.1 8.3 3.3l1.1 1.6 37.1 58.1s1.2 1.9 3.3 1.9c2.2 0 3.4-1.9 3.4-1.9l21.6-32.4 22.5 32.4s1.3 1.9 3.2 2c2 0 3.2-1.9 3.2-1.9l36.6-58.1 1.1-1.6c1.9-3 4.3-3.3 8.2-3.2h18.4l.1-29.9z" />
      <path d="M139.4 87l-20 31.7-25.8-37.4-19.8 29.2 41.8 65.6c1.4 2.5 2.6 2.8 3.2 2.9 1.3.1 2-.6 3.4-2.9l32.8-59.9c2.9-5.2 7.8-5.1 7.8-5.1h23.7V81.5h-37c-6.7-.1-10.1 5.5-10.1 5.5" />
    </g>
    <path d="M634 3.9V93c0 9.9-2 17.7-5.8 22.3-3.8 4.5-10.9 6.8-21.2 6.8-5.9 0-10.7-.8-14.2-2.5-3.4-1.7-6.1-3.8-8-6.4-1.9-2.6-3.2-5.7-3.8-9.1-.6-3.6-1-7.4-1-11.1V3.9h-34.8V93c0 19.6 5.5 34.3 16.3 43.9 10.7 9.4 26 14.2 45.3 14.2 19.1 0 34.3-4.8 45.2-14.3 11-9.6 16.6-24.3 16.6-43.8V3.9H634zM366.4 3.9l-21.3 97.3h-.7L322.3 3.9h-32l-22.5 96.2h-.7L246.6 3.9h-35.3l38.4 144H284l21.7-89.8h.6l22.1 89.8H362l39-144zM501.9 3.9l-29.9 101h-.9l-29.5-101h-36l46.9 144H490l48.1-144z" />
    <g fill={`${theme.colors.secondary}`} className="flyingwv">
      <path d="M145 173.9c0 3.8-3.1 6.8-7 6.8s-7-3-7-6.8 3.1-6.8 7-6.8 7 3 7 6.8m-12.3 0c0 3.1 2.3 5.5 5.3 5.5s5.2-2.4 5.2-5.4-2.2-5.5-5.3-5.5c-2.9 0-5.2 2.4-5.2 5.4m4.2 3.5h-1.6v-6.8c.6-.1 1.5-.2 2.6-.2 1.3 0 1.9.2 2.3.5.4.3.7.8.7 1.5 0 .8-.6 1.4-1.4 1.6v.1c.7.2 1 .7 1.2 1.7s.4 1.4.5 1.6h-1.7c-.2-.2-.3-.8-.5-1.6-.1-.7-.5-1.1-1.4-1.1h-.7v2.7zm.1-3.8h.7c.9 0 1.6-.3 1.6-1 0-.6-.5-1.1-1.5-1.1-.4 0-.7 0-.9.1l.1 2zM1987.9 139.7v-1h7.4v1h-3.1v8.3h-1.2v-8.3zM1998.1 138.7l2.9 7.8 2.9-7.8h1.7v9.3h-1.2v-7.7l-2.9 7.7h-1l-2.9-7.7h-.1v7.7h-1.1v-9.3z" />
    </g>
    <g fill={`${theme.colors.secondary}`} className="cancertype">
      <path d="M794.9 47.1c-4.1-20.5-20.5-30.9-40.7-30.9-34.6 0-49.2 28.5-49.2 58.8 0 33.1 14.6 60.8 49.4 60.8 25.4 0 40.1-17.9 41.9-41.9h19.4c-3.7 36.2-26.2 58.2-62.8 58.2-45.1 0-67.1-33.3-67.1-75.6 0-42.1 24-76.5 68.7-76.5 30.3 0 55.3 16.3 59.8 47.2l-19.4-.1zM920.2 148.1c-3 1.8-6.9 2.8-12.4 2.8-8.9 0-14.6-4.9-14.6-16.3-9.6 11.2-22.4 16.3-37 16.3-19.1 0-34.8-8.5-34.8-29.5 0-23.8 17.7-28.9 35.6-32.3 19.1-3.7 35.4-2.4 35.4-15.5 0-15-12.4-17.5-23.4-17.5-14.6 0-25.4 4.5-26.2 19.9h-17.3c1-26 21.1-35.2 44.5-35.2 18.9 0 39.5 4.3 39.5 28.9v54.1c0 8.1 0 11.8 5.5 11.8 1.4 0 3.1-.2 5.3-1l-.1 13.5zm-28-53.9c-6.7 4.9-19.7 5.1-31.3 7.1-11.4 2-21.1 6.1-21.1 18.9 0 11.4 9.8 15.5 20.3 15.5 22.8 0 32.1-14.2 32.1-23.8V94.2zM929 43.4h16.3v16.7h.4c7.3-13 19.1-19.1 34.2-19.1 27.7 0 36.2 15.9 36.2 38.4v69.1h-17.4V77.3c0-12.8-8.1-21.1-21.4-21.1-20.9 0-31.1 14-31.1 32.9v59.4h-17.3l.1-105.1zM1105.3 77.1c-2.6-13.2-11.8-20.9-26-20.9-25 0-32.7 19.7-32.7 41.1 0 19.3 8.7 38.4 30.7 38.4 16.7 0 26.6-9.8 28.9-25.6h17.7c-3.9 25.6-19.9 40.9-46.4 40.9-32.1 0-49.2-22.4-49.2-53.7 0-31.5 16.3-56.3 49.6-56.3 23.8 0 42.9 11.2 45.3 36.2l-17.9-.1zM1225.5 115.2c-4.7 23.2-21.4 35.8-44.9 35.8-33.6 0-49.4-23.2-50.4-55.3 0-31.5 20.7-54.7 49.4-54.7 37.2 0 48.6 34.8 47.6 60.2h-78.7c-.6 18.3 9.8 34.6 32.7 34.6 14.2 0 24.2-6.9 27.3-20.5l17-.1zm-16.7-29.3c-.8-16.5-13.2-29.7-30.3-29.7-18.1 0-28.9 13.6-30.1 29.7h60.4zM1237.8 43.4h16.3v22.2h.4c8.3-16.9 19.9-25.2 38.6-24.6v18.3c-27.9 0-38 15.9-38 42.5v46.8h-17.3V43.4z" />
    </g>
    <g>
      <path d="M1318.6 3.3h19.3v145.2h-19.3V3.3zM1360.5 43.4h16.3v16.7h.4c7.3-13 19.1-19.1 34.2-19.1 27.7 0 36.2 15.9 36.2 38.4v69.1h-17.3V77.3c0-12.8-8.1-21.1-21.4-21.1-20.9 0-31.1 14-31.1 32.9v59.4h-17.3V43.4zM1478.3 115.4c.6 15.5 14 20.3 28.3 20.3 10.8 0 25.4-2.4 25.4-15.7 0-13.4-17.1-15.7-34.4-19.5-17.1-3.9-34.4-9.6-34.4-29.7 0-21.1 20.9-29.9 39.2-29.9 23.2 0 41.7 7.3 43.1 32.9h-17.3c-1.2-13.4-13-17.7-24.4-17.7-10.4 0-22.4 2.8-22.4 13.4 0 12.4 18.3 14.4 34.4 18.3 17.3 3.9 34.4 9.6 34.4 29.9 0 25-23.4 33.1-44.7 33.1-23.6 0-43.5-9.6-44.5-35.6h17.3v.2zM1587.7 43.4h20.9v15.3h-20.9V124c0 7.9 2.2 9.4 13 9.4h7.9v15.3h-13.2c-17.9 0-25-3.7-25-23V58.8h-17.9V43.5h17.9V11.9h17.3v31.5zM1638.3 24.5H1621V3.3h17.3v21.2zm-17.2 18.9h17.3v105.1h-17.3V43.4zM1687.1 43.4h20.9v15.3h-20.9V124c0 7.9 2.2 9.4 13 9.4h7.9v15.3h-13.2c-17.9 0-25-3.7-25-23V58.8h-17.9V43.5h17.9V11.9h17.3v31.5zM1806.2 148.5h-16.3v-16.7h-.4c-7.3 13-19.1 19.1-34.2 19.1-27.7 0-36.2-15.9-36.2-38.4V43.4h17.3v71.2c0 12.8 8.1 21.1 21.4 21.1 20.9 0 31.1-14 31.1-32.9V43.4h17.3v105.1zM1850.7 43.4h20.9v15.3h-20.9V124c0 7.9 2.2 9.4 13 9.4h7.9v15.3h-13.2c-17.9 0-25-3.7-25-23V58.8h-17.9V43.5h17.9V11.9h17.3v31.5zM1970.4 115.2c-4.7 23.2-21.4 35.8-44.9 35.8-33.6 0-49.4-23.2-50.4-55.3 0-31.5 20.7-54.7 49.4-54.7 37.2 0 48.6 34.8 47.6 60.2h-78.7c-.6 18.3 9.8 34.6 32.7 34.6 14.2 0 24.2-6.9 27.3-20.5l17-.1zm-16.7-29.3c-.8-16.5-13.2-29.7-30.3-29.7-18.1 0-28.9 13.6-30.1 29.7h60.4z" />
    </g>
  </svg>
)

export default Logo
