import React from "react"
import { Box } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindowClose, faSearch } from "@fortawesome/pro-regular-svg-icons"

const SearchButton = (props) => {
  return (
    <Box
      as="button"
      onClick={props.toggleSearchBox}
      aria-label={`${props.isSearchBoxOn ? "close search" : "open search"}`}
      zIndex={900}
      sx={{
        background: "transparent",
        border: "none",
        display: "block",
        width: "50px",
        height: "50px",
        cursor: "pointer",
        position: "absolute",
        top: "0.15rem",
        right: props.isSearchBoxOn ? "0.2rem" : ["5em", "5em", "5em", "0.2rem"],

        "&:hover:not(.touch)": {
          background: "transparent",
          border: "none",
          outline: "none",
          transform: "scale(1.25, 1.25)",
          transitionProperty: "transform",
          transitionDuration: "0.15s",
        },
        ":focus": {
          background: "transparent",
          border: "none",
          outline: "none",
        },
      }}
    >
      <FontAwesomeIcon
        color={props.isSearchBoxOn ? "gray.700" : "white"}
        icon={props.isSearchBoxOn ? faWindowClose : faSearch}
        style={{ fontSize: "1.5em" }}
      />
    </Box>
  )
}

export default SearchButton
