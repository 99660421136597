import React from "react"
import { Box, Button, Stack, VStack } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"
import { Card } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const HopeAndHealing = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCancerInsideInstitute(sort: { fields: position, order: ASC }) {
        edges {
          node {
            slug
            title
            id
            intro
            heroImage {
              gatsbyImageData(width: 800)
            }
            contentNode {
              childMarkdownRemark {
                excerpt(format: PLAIN, truncate: false, pruneLength: 140)
              }
            }
          }
        }
      }
    }
  `)

  const hopehealingcard = data.allDatoCmsCancerInsideInstitute

  return (
    <>
      {/*get last element from array*/}
      {
        hopehealingcard.edges.map(({ node }) => (
          <motion.div whileHover={{ scale: 1.015 }} key={node.id}>
            <Card
              boxShadow="lg"
              bg="accent.4"
              _hover={{ boxShadow: "xl" }}
              width="full"
            >
              <Stack direction={["column", "column", "row"]} width="full">
                <Box
                  width={["full", "full", "1/2"]}
                  sx={{
                    ".gatsby-image-wrapper-constrained": { display: "block" },
                  }}
                >
                  <GatsbyImage
                    image={node.heroImage.gatsbyImageData}
                    alt={node.title}
                  />
                </Box>

                <VStack width={["full", "full", "1/2"]} alignSelf="center">
                  <Box width="full" height="full" color="white" p={6}>
                    <Box as="h3">{node.title}</Box>
                    <Box as="p" color="white">
                      {node.intro}
                    </Box>

                    <Link
                      to={`/about-us/inside-the-institute/${node.slug}`}
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      <Button as="span" variant="outline" colorScheme="white">
                        Read More
                      </Button>
                    </Link>
                  </Box>
                </VStack>
              </Stack>
            </Card>
          </motion.div>
        ))[3]
      }
    </>
  )
}

export default HopeAndHealing
