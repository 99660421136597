import React from "react"
import { Box, Flex, UnorderedList, ListItem } from "@chakra-ui/react"
import { StaticQuery, graphql, Link } from "gatsby"

import { NavLink } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const FooterNav = (props) => (
  <StaticQuery
    query={graphql`
      {
        datoCmsCancerResearch {
          id
          slug
          title
        }
      }
    `}
    render={(data) => (
      <Box width="full" py={3} sx={{ "*": { fontSize: "sm" } }}>
        <Box
          px={0}
          width="full"
          sx={{
            ul: {
              listStyle: "none",
              margin: "0 0 2rem 0",
              padding: 0,
            },
            li: {
              display: "block",
              marginBottom: `calc(1.45rem / 2.5)`,
              paddingLeft: 0,
            },
            "li a": {
              color: "text",
              textDecoration: "none",
            },
            "li a:hover": {
              textDecoration: "underline",
            },
          }}
        >
          <Flex mx={0} flexWrap="wrap" justifyContent="space-between">
            {/* Patients & Visitors */}
            <Box px={[0, 0, 2, 6]} width={["full", "full", "50%", "25%"]}>
              <Box fontSize="sm" fontWeight="semibold" marginBottom={2}>
                <strong>Patients & Visitors</strong>
              </Box>
              <UnorderedList m={0} p={0}>
                <ListItem>
                  <Link to="/patients-visitors#cancers">Cancers We Treat</Link>
                </ListItem>
                <ListItem>
                  <Link to="/patients-visitors#services">
                    Treatment & Services
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/patients-visitors/resources">
                    Patient & Visitor Resources
                  </Link>
                </ListItem>
                <ListItem>
                  <a href="https://mywvuchart.com/MyChart/">MyWVUChart</a>
                </ListItem>
                <ListItem>
                  <Link to="/about-us/inside-the-institute/cancer-prevention-and-control">
                    Cancer Prevention & Control
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/about-us/podcasts/">
                    Podcast Series: Living Beyond Cancer
                  </Link>
                </ListItem>
              </UnorderedList>
            </Box>

            {/* For Medical Professionals */}
            <Box px={[0, 0, 2, 6]} width={["full", "full", "50%", "25%"]}>
              <Box fontSize="sm" fontWeight="semibold" marginBottom={2}>
                <strong>For Medical Professionals</strong>
              </Box>
              <UnorderedList m={0} p={0}>
                <ListItem>
                  <Link to="/for-medical-professionals/refer-patient">
                    Refer a Patient
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/for-medical-professionals/transfer-patient">
                    Transfer a Patient
                  </Link>
                </ListItem>

                {/*
                <ListItem>
                  <a href="https://www.datocms-assets.com/30187/1623336701-2021june-cancerrackcard-final.pdf">
                    Specialists Directory
                  </a>
                </ListItem>
                */}
              </UnorderedList>
            </Box>

            {/* Research */}
            <Box px={[0, 0, 2, 6]} width={["full", "full", "50%", "25%"]}>
              <Box fontSize="sm" fontWeight="semibold" marginBottom={2}>
                <strong>Research</strong>
              </Box>
              <UnorderedList m={0} p={0}>
                <ListItem>
                  <a href="https://www.hsc.wvu.edu/ctru/">
                    Clinical Research Unit
                  </a>
                </ListItem>
                <ListItem>
                  <Link to="/research/research-programs">
                    Research Programs
                  </Link>
                </ListItem>
                <ListItem>
                  <a href="https://www.hsc.wvu.edu/resoff/research/shared-research-resources/">
                    Shared Research Resources
                  </a>
                </ListItem>
                <ListItem>
                  <Link to="/research/membership">Membership</Link>
                </ListItem>
                <ListItem>
                  <Link to="/research#highlighted-researchers">
                    Highlighted Researchers
                  </Link>
                </ListItem>
              </UnorderedList>
            </Box>

            {/* About */}
            <Box px={[0, 0, 2, 6]} width={["full", "full", "50%", "25%"]}>
              <Box fontSize="sm" fontWeight="semibold" marginBottom={2}>
                <strong>About Us</strong>
              </Box>
              <UnorderedList m={0} p={0}>
                <NavLink>
                  <Link to="/about-us#intro">Who We Are</Link>
                </NavLink>
                <NavLink>
                  <Link to="/about-us#locations">Locations</Link>
                </NavLink>
                <NavLink>
                  <Link to="/about-us#leadership">Leadership</Link>
                </NavLink>
                <NavLink>
                  <Link to="/about-us/accreditations-designations">
                    Accreditations and Designations
                  </Link>
                </NavLink>
                <NavLink>
                  <Link to="/#inside">Inside the Institute</Link>
                </NavLink>
                <NavLink>
                  <Link to="/about-us#highlighted-providers">
                    Highlighted Providers
                  </Link>
                </NavLink>
                <NavLink>
                  <Link to="/#highlighted-teams">Highlighted Teams</Link>
                </NavLink>
                <NavLink>
                  <a href="https://www.wvumedicine.org/careers">Careers</a>
                </NavLink>
              </UnorderedList>

              <Box fontSize="sm" fontWeight="semibold" marginBottom={2}>
                <strong>Giving</strong>
              </Box>
              <UnorderedList m={0} p={0}>
                <NavLink>
                  <Link to="/giving#ways">Ways to Give</Link>
                </NavLink>
                <NavLink>
                  <Link to="/giving">Why Give</Link>
                </NavLink>
              </UnorderedList>
            </Box>
          </Flex>
        </Box>
      </Box>
    )}
  ></StaticQuery>
)

export default FooterNav
