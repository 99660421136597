import React from "react"
import useSWR from "swr"
import {
  Box,
  Center,
  Link as LinkExt,
  ListItem,
  Spinner,
  UnorderedList,
} from "@chakra-ui/react"
import { reverse } from "lodash"

import fetcher from "../../utils/fetcher"

const SpecialistsDirService = (props) => {
  const { data, error } = useSWR(
    `https://mobile.wvumedicine.org/wayfinder/api/findadoc/subgroup/${props.id}`,
    fetcher
  )

  if (error) return <div>failed to load</div>

  if (!data)
    return (
      <Box width="full" height="full">
        <Center width="full" height="full">
          <Spinner size="xl" color="primary" />
        </Center>
      </Box>
    )

  const Providers = data.map((provider) => provider)

  // console.info(data)

  return (
    <>
      {Providers.length > 0 && <Box as="h3">Providers</Box>}
      <UnorderedList m={0} p={0} listStyleType="none">
        {reverse(
          Providers.map(({ ...provider }) => (
            <ListItem
              key={provider.personID}
              borderBottomColor="blackAlpha.100"
              borderBottomWidth={1}
              mb={0}
              py={1}
            >
              <LinkExt href={provider.webpage} fontSize="sm" lineHeight={1}>
                <Box as="span" fontWeight="bold">
                  {`${provider.firstName} ${provider.lastName}`}
                </Box>{" "}
                {provider.credentials.map(({ credentialName }) => (
                  <>{credentialName} </>
                ))}
              </LinkExt>
            </ListItem>
          ))
        )}
      </UnorderedList>
    </>
  )
}

export default SpecialistsDirService
